import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from 'src/@util/loading/loading.service';
import { DateService } from 'src/app/@core/functions/date.service';
import { JobsService } from 'src/app/@core/jobs/jobs.service';
import { LastJobService } from 'src/app/@core/jobs/lastJob.service';
var WorkHistoryPrintComponent = /** @class */ (function () {
    function WorkHistoryPrintComponent(loading, route, jobService, dateService, lastJobService) {
        this.loading = loading;
        this.route = route;
        this.jobService = jobService;
        this.dateService = dateService;
        this.lastJobService = lastJobService;
    }
    WorkHistoryPrintComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.paramMap.subscribe(function (j) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.initDataSource();
                return [2 /*return*/];
            });
        }); });
    };
    WorkHistoryPrintComponent.prototype.initDataSource = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sslgId, prevJobId;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        sslgId = localStorage.getItem('sslgId');
                        prevJobId = localStorage.getItem('prevJobId');
                        return [4 /*yield*/, this.jobService.getJobInfo(sslgId, prevJobId).subscribe(function (data) {
                                var formattedData = data;
                                if (formattedData) {
                                    formattedData.startDate = _this.dateService.setDateFormat(formattedData.startDate);
                                    formattedData.endDate = _this.dateService.setDateFormat(formattedData.endDate);
                                }
                                _this.jobOverview = formattedData;
                            })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.lastJobService.getDetails(sslgId, prevJobId).subscribe(function (data) {
                                _this.detailsEntity = data;
                            })];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.lastJobService.getAdditionalInfo(sslgId, prevJobId).subscribe(function (data) {
                                _this.additionalInfoEntity = data;
                            })];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    WorkHistoryPrintComponent.prototype.formatCurr = function (value) {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    };
    WorkHistoryPrintComponent.prototype.print = function () {
        window.print();
    };
    WorkHistoryPrintComponent.prototype.formatExertional = function (value) {
        if (value) {
            switch (value) {
                case 0:
                    return 'None';
                case 0.5:
                    return value + ' hours';
                case 1:
                    return value + ' hour';
                case 2:
                    return value + ' hours (not very often)';
                case 4:
                    return value + ' hours (part of the day)';
                case 6:
                    return value + ' hours (most of the day)';
                case 8:
                    return value + ' hours or more';
                default:
                    return value + ' hours';
            }
        }
    };
    WorkHistoryPrintComponent.prototype.formatDate = function (date) {
        if (date) {
            var dateObject = new Date(date);
            var day = String(dateObject.getDate()).padStart(2, '0');
            var month = String(dateObject.getMonth() + 1).padStart(2, '0');
            var year = dateObject.getFullYear();
            return month + "/" + day + "/" + year;
        }
        else {
            return '';
        }
    };
    return WorkHistoryPrintComponent;
}());
export { WorkHistoryPrintComponent };
