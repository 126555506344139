import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { JOBS_CONFIG } from '../../@config/api';
import { filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var LastJobService = /** @class */ (function () {
    function LastJobService(http) {
        this.http = http;
        this.config = JOBS_CONFIG;
    }
    LastJobService.prototype.saveDescription = function (sslgId, data) {
        return this.http.post(this.config.LAST.DescriptionSave(sslgId), data);
    };
    LastJobService.prototype.getDescription = function (sslgId) {
        return this.http.get(this.config.LAST.Description(sslgId));
    };
    LastJobService.prototype.saveDetails = function (sslgId, data) {
        return this.http.post(this.config.LAST.DetailsSave(sslgId), data);
    };
    LastJobService.prototype.getDetails = function (sslgId, previousJobId) {
        return this.http.get(this.config.LAST.Details(sslgId, previousJobId));
    };
    LastJobService.prototype.saveAdditionalInfo = function (sslgId, data) {
        return this.http.post(this.config.LAST.AdditionalInfoSave(sslgId), data);
    };
    LastJobService.prototype.getAdditionalInfo = function (sslgId, previousJobId) {
        return this.http.get(this.config.LAST.AdditionalInfo(sslgId, previousJobId));
    };
    LastJobService.prototype.saveJob = function (data) {
        return this.http.post(this.config.saveJob, data);
    };
    LastJobService.prototype.sendLastJobByEmail = function (emails, prevJobId, sslgId) {
        var data = { emails: emails, prevJobId: prevJobId, sslgId: sslgId };
        return this.request(this.config.sendEmail, data);
    };
    LastJobService.prototype.request = function (url, data) {
        var req = new HttpRequest('POST', url, data, { responseType: 'arraybuffer' });
        return this.http.request(req).pipe(filter(function (event) { return event instanceof HttpResponse; }));
    };
    LastJobService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LastJobService_Factory() { return new LastJobService(i0.ɵɵinject(i1.HttpClient)); }, token: LastJobService, providedIn: "root" });
    return LastJobService;
}());
export { LastJobService };
