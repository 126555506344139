import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Social } from '../@models/system-managment/social';
import * as conf from '../@config/api';
import { Functions } from "../../@util/functions";
import { FileUploadingService } from "../../@util/file-uploading-progress/file-uploading.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../@util/file-uploading-progress/file-uploading.service";
var ClientsService = /** @class */ (function () {
    function ClientsService(http, fileUploadingService) {
        this.http = http;
        this.fileUploadingService = fileUploadingService;
        this.config = conf.CLIENT_USER_CONFIG;
        this.clientInfoUpdated = new EventEmitter();
    }
    ClientsService.prototype.forgotPassword = function (username) {
        return this.http.post(this.config.forgotPassword, { username: username });
    };
    ClientsService.prototype.addUser = function (data) {
        return this.http.post(this.config.add, data);
    };
    ClientsService.prototype.getUsersList = function () {
        return this.http.get(this.config.getAll);
    };
    ClientsService.prototype.findBasicData = function (userId) {
        return this.http.get(this.config.findBasicData(userId));
    };
    ClientsService.prototype.findSocialData = function (userId) {
        return this.http.get(this.config.findSocialData(userId));
    };
    ClientsService.prototype.saveClientRemarks = function (sslgId, data) {
        return this.http.post(this.config.setClientRemarks(sslgId), data);
    };
    ClientsService.prototype.getClientRemarks = function (sslgId) {
        return this.http.get(this.config.getClientRemarks(sslgId));
    };
    ClientsService.prototype.saveClientCompBenefits = function (sslgId, data) {
        return this.http.post(this.config.setClientCompBenefits(sslgId), data);
    };
    ClientsService.prototype.getClientCompBenefits = function (sslgId) {
        return this.http.get(this.config.getClientCompBenefits(sslgId));
    };
    ClientsService.prototype.getCurrentUser = function (userId) {
        return this.http.get(this.config.getById(userId));
    };
    ClientsService.prototype.updatePasswordAndActive = function (value) {
        return this.http.post(this.config.updateLoginInfo(value.username), value);
    };
    ClientsService.prototype.setSocial = function (userName, value) {
        Social.checkLinck(value);
        return this.http.post(this.config.setSocial(userName), value);
    };
    ClientsService.prototype.setClientBasic = function (username, value) {
        return this.http.post(this.config.setClientBasic(username), value);
    };
    ClientsService.prototype.setClientContact = function (username, value) {
        value.cell = Functions.checkPhone(value.cell);
        value.fax = Functions.checkPhone(value.fax);
        value.phone = Functions.checkPhone(value.phone);
        return this.http.post(this.config.setClientContact(username), value);
    };
    ClientsService.prototype.setClientAddress = function (username, value) {
        return this.http.post(this.config.setClientAddress(username), value);
    };
    ClientsService.prototype.uploadAvatar = function (username, avatar) {
        return this.fileUploadingService.uploadFile('/api/user/avatar/' + username, { file: avatar, fileInfo: null });
    };
    ClientsService.prototype.getAvatarName = function (username) {
        return this.http.get('/api/user/avatar/' + username + '/name');
    };
    ClientsService.prototype.deleteUserAvatar = function (username) {
        return this.http.post('/api/user/avatar/' + username + '/remove', {});
    };
    ClientsService.prototype.delete = function (sslgId) {
        return this.http.post('/api/users/delete', { sslgId: sslgId });
    };
    ClientsService.prototype.addPrefix = function (obj, prefix) {
        return Object.fromEntries(Object.entries(obj).map(function (_a) {
            var _b = tslib_1.__read(_a, 2), key = _b[0], value = _b[1];
            return ["" + prefix + key, value];
        }));
    };
    ClientsService.prototype.removePrefix = function (obj, prefix) {
        var prefixLength = prefix.length;
        return Object.fromEntries(Object.entries(obj).map(function (_a) {
            var _b = tslib_1.__read(_a, 2), key = _b[0], value = _b[1];
            return [key.startsWith(prefix) ? key.slice(prefixLength) : key, value];
        }));
    };
    ClientsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClientsService_Factory() { return new ClientsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.FileUploadingService)); }, token: ClientsService, providedIn: "root" });
    return ClientsService;
}());
export { ClientsService };
