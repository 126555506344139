import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AUTH_CONFIG } from '../@config/api';
import { Subject } from 'rxjs';
import { CustomIrisNotificationService } from "../../@util/custom-iris-notification/custom-iris-notification.service";
import { map } from "rxjs/operators";
import { DialogService } from "../../@util/dialog/dialog.service";
import { PERMISSIONS } from '../@config/permissions';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../@util/custom-iris-notification/custom-iris-notification.service";
import * as i3 from "../../@util/dialog/dialog.service";
var UserInfoService = /** @class */ (function () {
    function UserInfoService(http, notification, dialog) {
        this.http = http;
        this.notification = notification;
        this.dialog = dialog;
        this.storage = sessionStorage;
        this.userInfo = new Subject();
    }
    UserInfoService.prototype.loadCurent = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.http.get(AUTH_CONFIG.current).pipe(map(function (j) {
                                // if (this.current == null && (+j.passwordChangeDaysLeft) > 80) {
                                // 	this.notification.show(<any>{
                                // 		autoHide: true,
                                // 		timeOut: 15000,
                                // 		message: `Your access will be revoked after ${j.passwordChangeDaysLeft} days.`,
                                // 		title: "Change your password!",
                                // 		type: 'info',
                                // 		position: "rb",
                                // 		imagePosition: 'right', // left or right
                                // 		progressBar: 'linear', //linear or circle
                                // 	});
                                // }
                                return j;
                            })).toPromise()];
                    case 1:
                        _a.current = (_b.sent()).user;
                        this.userInfo.next(this.current);
                        return [2 /*return*/];
                }
            });
        });
    };
    UserInfoService.prototype.getCurrentUserName = function () {
        return this.storage.getItem('currentUserName');
    };
    UserInfoService.prototype.getCurrentFirebaseToken = function () {
        return this.storage.getItem('currentFirebaseToken');
    };
    UserInfoService.prototype.setCurrentFirebaseToken = function (token) {
        this.storage.setItem('currentFirebaseToken', token);
    };
    UserInfoService.prototype.getCurrentUserRole = function () {
        return this.storage.getItem('currentUserRole');
    };
    UserInfoService.prototype.getCurrentUserNameIsSystem = function () {
        if (this.getCurrentUserName() == 'CEO') {
            return true;
        }
        return false;
    };
    UserInfoService.prototype.isAdjuster = function () {
        if (this.getCurrentUserRole() == 'ADJUSTER') {
            return true;
        }
        return false;
    };
    UserInfoService.prototype.isStaff = function () {
        if (PERMISSIONS.HIGHER_EXEC_LEVEL_4.includes(this.getCurrentUserRole())) {
            return true;
        }
        return false;
    };
    UserInfoService.prototype.someStaff = function () {
        return !(this.getCurrentUserRole().indexOf('ADJUSTER') != -1 || this.getCurrentUserRole().indexOf('CLIENT') != -1);
    };
    UserInfoService.prototype.isSslgStaff = function () {
        if (this.getCurrentUserRole() == 'SSLG_STAFF') {
            return true;
        }
        return false;
    };
    UserInfoService.prototype.isClaimant = function () {
        if (this.getCurrentUserRole() == 'CLIENT') {
            return true;
        }
        return false;
    };
    UserInfoService.prototype.setAudioNotifiicationRunning = function () {
        this.storage.setItem('audioNotifiication', "1");
    };
    UserInfoService.prototype.setDeviceData = function (uuid, type) {
        this.storage.setItem('deviceUuid', uuid);
        this.storage.setItem('deviceType', type);
    };
    UserInfoService.prototype.getDeviceUuid = function () {
        return this.storage.getItem('deviceUuid');
    };
    UserInfoService.prototype.getDeviceType = function () {
        return this.storage.getItem('deviceType');
    };
    UserInfoService.prototype.isApp = function () {
        if (this.getDeviceType() !== undefined && this.getDeviceType() !== null) {
            return true;
        }
        return false;
    };
    UserInfoService.prototype.getAudioNotifiicationRunning = function () {
        return this.storage.getItem('audioNotifiication');
    };
    UserInfoService.prototype.getToken = function () {
        var currentUser = JSON.parse(this.storage.getItem('currentUser'));
        return currentUser.token;
    };
    UserInfoService.prototype.getCurrentUrlPosData = function (pos) {
        var url = window.location.href;
        var parts = url.split('/');
        return parts[pos] || '';
    };
    UserInfoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserInfoService_Factory() { return new UserInfoService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CustomIrisNotificationService), i0.ɵɵinject(i3.DialogService)); }, token: UserInfoService, providedIn: "root" });
    return UserInfoService;
}());
export { UserInfoService };
