import {Injectable} from '@angular/core';
import {HttpClient, HttpRequest, HttpResponse} from '@angular/common/http';
import * as conf from '../@config/api';
import {UserInfoService} from "./user-info.service";
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private config = conf.DASHBOARD_CONFIG;

  constructor(private http: HttpClient,
			  private userInfoService: UserInfoService,) {
  }

  getCardsInfo() {
    return this.http.get<any>( this.config.header.cardInfo );
  }

	getInfo(username?) {
		return this.http.post<any>(this.config.header.info,{userName:username});
	}

  getAdjusterCardsInfo() {
  	return this.http.get<any>(this.config.header.adjusterCardInfo);
  }

  getClaimantHistory() {
    return this.http.get<{key: any, value: number}[]>(this.config.header.claimantHistory);
  }

	getUserName(){
		return this.userInfoService.getCurrentUserName();
	}

  sendConsolidatorListByEmail(emails, ddsId, username?){
    const data = {emails:emails, ddsOfficeId:ddsId};
    return this.request(this.config.header.sendEmailsToConsolidator(username), data);
  }

  private request(url, data) {
		const req = new HttpRequest('POST', url, data, {responseType: 'arraybuffer'});
		return this.http.request(req).pipe(
      filter(event => event instanceof HttpResponse)
    );
	}
}
