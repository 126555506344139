import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {JOBS_CONFIG} from '../../@config/api';
import {JobItemView} from "../../@models/jobs/jobs.model";
import {HospitalItem} from "../../@models/medical/hospital-item";

@Injectable({
  providedIn: 'root'
})
export class JobsService {
  private CONFIG = JOBS_CONFIG;

  constructor(private http: HttpClient) {
  }

  getPreviousJobsList(sslgId) {
    return this.http.post<any>(this.CONFIG.getList, {sslgId});
  }

  getJobInfo(sslgId, id: string) {
    return this.http.post<any>(this.CONFIG.getItemInfo, {sslgId, id});
  }

  saveJob(sslgId, result, current: JobItemView) {
    //return this.http.post<any>(this.CONFIG.saveItem, {sslgId, result, current});
	  return this.http.post<any>(this.CONFIG.saveItem, result);
  }

  deleteJob(sslgId, id) {
    return this.http.post<HospitalItem>(this.CONFIG.deleteJob, {sslgId, id});
  }

  setWorkHistoryData(data, type){
    localStorage.setItem(type, JSON.stringify(data));
  }

  getWorkHistoryData(type){
    const parsedData = localStorage.getItem(type);
    return JSON.parse(parsedData);
  }

  clearJobLocalStorage(){
    localStorage.removeItem('currentStep');
    localStorage.removeItem('workHistory');
    localStorage.removeItem('jobOverView');
    localStorage.removeItem('dayToDayJob');
    localStorage.removeItem('exertionalDemands');
    localStorage.removeItem('liftRequirement');
    localStorage.removeItem('supervisoryDuties');
  }
}
