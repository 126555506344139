import { HttpClient } from '@angular/common/http';
import { JOBS_CONFIG } from '../../@config/api';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var JobsService = /** @class */ (function () {
    function JobsService(http) {
        this.http = http;
        this.CONFIG = JOBS_CONFIG;
    }
    JobsService.prototype.getPreviousJobsList = function (sslgId) {
        return this.http.post(this.CONFIG.getList, { sslgId: sslgId });
    };
    JobsService.prototype.getJobInfo = function (sslgId, id) {
        return this.http.post(this.CONFIG.getItemInfo, { sslgId: sslgId, id: id });
    };
    JobsService.prototype.saveJob = function (sslgId, result, current) {
        //return this.http.post<any>(this.CONFIG.saveItem, {sslgId, result, current});
        return this.http.post(this.CONFIG.saveItem, result);
    };
    JobsService.prototype.deleteJob = function (sslgId, id) {
        return this.http.post(this.CONFIG.deleteJob, { sslgId: sslgId, id: id });
    };
    JobsService.prototype.setWorkHistoryData = function (data, type) {
        localStorage.setItem(type, JSON.stringify(data));
    };
    JobsService.prototype.getWorkHistoryData = function (type) {
        var parsedData = localStorage.getItem(type);
        return JSON.parse(parsedData);
    };
    JobsService.prototype.clearJobLocalStorage = function () {
        localStorage.removeItem('currentStep');
        localStorage.removeItem('workHistory');
        localStorage.removeItem('jobOverView');
        localStorage.removeItem('dayToDayJob');
        localStorage.removeItem('exertionalDemands');
        localStorage.removeItem('liftRequirement');
        localStorage.removeItem('supervisoryDuties');
    };
    JobsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function JobsService_Factory() { return new JobsService(i0.ɵɵinject(i1.HttpClient)); }, token: JobsService, providedIn: "root" });
    return JobsService;
}());
export { JobsService };
