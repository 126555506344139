import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './auth/login/login.component';
import {RestoreComponent} from './auth/restore/restore.component';
import {AuthGuard} from './auth/_guards/auth.guard';
import {NgModule} from '@angular/core';
import {PdfViewComponent} from "./pdf-view/pdf-view.component";
import {TitleRouterResolver} from "./title-router-resolver";
import {MedicalTreatingReportPageComponent} from "./@view/medical-treating-report-page/medical-treating-report-page.component";
import { ClaimantPrintRecordPageComponent } from './@view/claimant-print-record-page/claimant-print-record-page.component';
import { ConsolidatorReportListComponent } from './@view/consolidator-report-list/consolidator-report-list.component';
import { WorkHistoryPrintComponent } from './@view/work-history-print/work-history-print.component';

const appRoutes: Routes = [
	{
		path: 'login', component: LoginComponent, data: {animation: 'Login'}
	},
	{
		path: 'restore/:email/:hash', component: RestoreComponent
	},
	{
		path: 'report', loadChildren: () => import('./@view/reports/reports.module')
			.then(m => m.ReportsModule), canActivate: [AuthGuard]
	},
	{
		path: 'view-report', component: PdfViewComponent
	},
	{
		path: 'view-medical-treating-report', component: MedicalTreatingReportPageComponent
	},
	{
		path: 'consolidator-report', component: ConsolidatorReportListComponent
	},
	{
		path: 'work-history-report', component: WorkHistoryPrintComponent
	},
	{
		path: 'view-print-claimant', component: ClaimantPrintRecordPageComponent
	},
	{
		path: '', loadChildren: () => import('./@view/view.module').then(m => m.ViewModule),
		canActivate: [AuthGuard], data: {animation: 'Home'}, resolve:  { test: TitleRouterResolver }
	},
	{path: '**', redirectTo: '', pathMatch: 'full'},
];

@NgModule({
	imports: [RouterModule.forRoot(appRoutes, { enableTracing: false })],
	exports: [RouterModule],
	providers: [AuthGuard]
})
export class AppRoutingModule {
}
