import {EventEmitter, Injectable} from '@angular/core';
import {Personal} from '../@models/system-managment/personal';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Social} from '../@models/system-managment/social';
import * as conf from '../@config/api';
import {User} from '../@models/system-managment/user';
import {Contact} from '../@models/contact';
import {ClientBasic} from '../@models/system-managment/client-basic';
import {ClientContact} from '../@models/system-managment/client-contact';
import {ClientAddress} from '../@models/system-managment/client-address';
import {Functions} from "../../@util/functions";
import {FileUploadingService} from "../../@util/file-uploading-progress/file-uploading.service";
import {ClientSocialModel} from "../@models/system-managment/client-social.model";

@Injectable({
	providedIn: 'root'
})
export class ClientsService {
	private config = conf.CLIENT_USER_CONFIG;
	clientInfoUpdated: EventEmitter<any> = new EventEmitter();

	constructor(private http: HttpClient, private fileUploadingService: FileUploadingService) {

	}

	forgotPassword(username: string) {
		return this.http.post<any>(this.config.forgotPassword ,{username: username});

	}

	addUser(data) {
		return this.http.post(this.config.add, data);
	}

	getUsersList() {
		return this.http.get(this.config.getAll);
	}

	findBasicData(userId){
		return this.http.get<User>(this.config.findBasicData(userId));
	}

	findSocialData(userId){
		return this.http.get<ClientSocialModel>(this.config.findSocialData(userId));
	}

	saveClientRemarks(sslgId: string, data: any) {
		return this.http.post<any>(this.config.setClientRemarks(sslgId), data);
	}

	getClientRemarks(sslgId: string) {
		return this.http.get<any>(this.config.getClientRemarks(sslgId));
	}

	saveClientCompBenefits(sslgId: string, data: any) {
		return this.http.post<any>(this.config.setClientCompBenefits(sslgId), data);
	}

	getClientCompBenefits(sslgId: string) {
		return this.http.get<any>(this.config.getClientCompBenefits(sslgId));
	}

	getCurrentUser(userId) {
		return this.http.get<User>(this.config.getById(userId));
	}

	updatePasswordAndActive(value: User) {
		return this.http.post(this.config.updateLoginInfo(value.username), value);
	}


	setSocial(userName: string, value: Social) {
		Social.checkLinck(value);
		return this.http.post(this.config.setSocial(userName), value);
	}

	setClientBasic(username: string, value: ClientBasic) {
		return this.http.post(this.config.setClientBasic(username), value);
	}

	setClientContact(username: string, value: ClientContact) {
		value.cell = Functions.checkPhone(value.cell);
		value.fax = Functions.checkPhone(value.fax);
		value.phone = Functions.checkPhone(value.phone);
		return this.http.post(this.config.setClientContact(username), value);
	}

	setClientAddress(username: string, value: ClientAddress) {
		return this.http.post(this.config.setClientAddress(username), value);
	}

	uploadAvatar(username: string, avatar: File) {
		return this.fileUploadingService.uploadFile('/api/user/avatar/' + username, {file: avatar, fileInfo: null});
	}

	getAvatarName(username: string) {
		return this.http.get('/api/user/avatar/' + username + '/name');
	}

	deleteUserAvatar(username) {
		return this.http.post('/api/user/avatar/' + username + '/remove', {});
	}

	delete(sslgId) {
		return this.http.post('/api/users/delete', {sslgId:sslgId});
	}

	addPrefix(obj, prefix){
		return Object.fromEntries(Object.entries(obj).map(([key, value]) => [`${prefix}${key}`, value]));
	}

	removePrefix(obj, prefix){
		const prefixLength = prefix.length;
		return Object.fromEntries(
			Object.entries(obj).map(([key, value]) => [key.startsWith(prefix) ? key.slice(prefixLength) : key, value])
		);
	}
}
