import {Injectable} from '@angular/core';
import {HttpClient, HttpRequest, HttpResponse} from '@angular/common/http';
import {JOBS_CONFIG} from '../../@config/api';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LastJobService {
  private config = JOBS_CONFIG;

  constructor(
    private http: HttpClient) {
  }

  saveDescription(sslgId: string, data: any) {
    return this.http.post<any>(this.config.LAST.DescriptionSave(sslgId), data);
  }

  getDescription(sslgId: string) {
    return this.http.get<any>(this.config.LAST.Description(sslgId));
  }

  saveDetails(sslgId: string, data: any) {
    return this.http.post<any>(this.config.LAST.DetailsSave(sslgId), data);
  }

  getDetails(sslgId: string, previousJobId:any) {
    return this.http.get<any>(this.config.LAST.Details(sslgId, previousJobId));
  }

  saveAdditionalInfo(sslgId: string, data: any) {
    return this.http.post<any>(this.config.LAST.AdditionalInfoSave(sslgId), data);
  }

  getAdditionalInfo(sslgId: string, previousJobId:any) {
    return this.http.get<any>(this.config.LAST.AdditionalInfo(sslgId, previousJobId));
  }

  saveJob(data){
    return this.http.post<any>(this.config.saveJob, data);
  }

  sendLastJobByEmail(emails, prevJobId, sslgId){
    const data = {emails:emails, prevJobId:prevJobId, sslgId:sslgId};
    return this.request(this.config.sendEmail, data);
  }

  private request(url, data) {
		const req = new HttpRequest('POST', url, data, {responseType: 'arraybuffer'});
		return this.http.request(req).pipe(
      filter(event => event instanceof HttpResponse)
    );
	}
}
