import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import * as conf from '../@config/api';
import { UserInfoService } from "./user-info.service";
import { filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./user-info.service";
var DashboardService = /** @class */ (function () {
    function DashboardService(http, userInfoService) {
        this.http = http;
        this.userInfoService = userInfoService;
        this.config = conf.DASHBOARD_CONFIG;
    }
    DashboardService.prototype.getCardsInfo = function () {
        return this.http.get(this.config.header.cardInfo);
    };
    DashboardService.prototype.getInfo = function (username) {
        return this.http.post(this.config.header.info, { userName: username });
    };
    DashboardService.prototype.getAdjusterCardsInfo = function () {
        return this.http.get(this.config.header.adjusterCardInfo);
    };
    DashboardService.prototype.getClaimantHistory = function () {
        return this.http.get(this.config.header.claimantHistory);
    };
    DashboardService.prototype.getUserName = function () {
        return this.userInfoService.getCurrentUserName();
    };
    DashboardService.prototype.sendConsolidatorListByEmail = function (emails, ddsId, username) {
        var data = { emails: emails, ddsOfficeId: ddsId };
        return this.request(this.config.header.sendEmailsToConsolidator(username), data);
    };
    DashboardService.prototype.request = function (url, data) {
        var req = new HttpRequest('POST', url, data, { responseType: 'arraybuffer' });
        return this.http.request(req).pipe(filter(function (event) { return event instanceof HttpResponse; }));
    };
    DashboardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DashboardService_Factory() { return new DashboardService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.UserInfoService)); }, token: DashboardService, providedIn: "root" });
    return DashboardService;
}());
export { DashboardService };
