import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from 'src/@util/loading/loading.service';
import { DateService } from 'src/app/@core/functions/date.service';
import { JobsService } from 'src/app/@core/jobs/jobs.service';
import { LastJobService } from 'src/app/@core/jobs/lastJob.service';

@Component({
  selector: 'app-work-history-print',
  templateUrl: './work-history-print.component.html',
  styleUrls: ['./work-history-print.component.scss']
})
export class WorkHistoryPrintComponent implements OnInit {

  jobOverview: any;
  detailsEntity: any; // dayToDayJob, exertionalDemands
  additionalInfoEntity: any; //liftRequirement , supervisoryDutiesCorrect

	constructor(private loading: LoadingService,
				private route: ActivatedRoute,
        private jobService: JobsService,
        private dateService: DateService,
        private lastJobService: LastJobService) {
	}

	navLinks: ({ path: string; label: string })[];
	ngOnInit() {
		this.route.paramMap.subscribe(async j => {
			this.initDataSource();
		});

	}

	async initDataSource() {

      const sslgId = localStorage.getItem('sslgId');
      const prevJobId = localStorage.getItem('prevJobId');

      await this.jobService.getJobInfo(sslgId, prevJobId).subscribe(data => {
        const formattedData = data;
        if(formattedData){
          formattedData.startDate = this.dateService.setDateFormat(formattedData.startDate);
          formattedData.endDate = this.dateService.setDateFormat(formattedData.endDate);
        }
        this.jobOverview = formattedData;
        
      });

      await this.lastJobService.getDetails(sslgId, prevJobId).subscribe(data => {
        this.detailsEntity = data;
      });

      await this.lastJobService.getAdditionalInfo(sslgId, prevJobId).subscribe(data => {
        this.additionalInfoEntity = data;
      });
	}

  formatCurr(value){
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  }
	print(){
		window.print();
	}
  
  formatExertional(value){
    if(value){
      switch(value){
        case 0:
          return 'None';
        case 0.5:
          return value+' hours';
        case 1:
          return value+' hour';
        case 2:
          return value+' hours (not very often)';
        case 4:
          return value+' hours (part of the day)';
        case 6:
          return value+' hours (most of the day)';
        case 8:
          return value+' hours or more';
        default:
          return value+ ' hours';
      }
    }
  }

  formatDate(date){
    if(date){
      const dateObject = new Date(date);
      const day = String(dateObject.getDate()).padStart(2, '0');
      const month = String(dateObject.getMonth() + 1).padStart(2, '0');
      const year = dateObject.getFullYear();
      return `${month}/${day}/${year}`;
    }else{
      return '';
    }
  }

}
