<div class="view-wrapper">
	<div  class="view-wrapper view-wrapper--no-mobile-padding" #report>
		<div #body class="visit-card">
			<div class="print-button no-print" style="margin-bottom: 20px; z-index: 1; margin-right: 27px;">
				<div class="form-buttons">
					<form-button (clickOnButton)="print()">Print</form-button>
				</div>
			</div>
            <div style="display: flex;flex-wrap: wrap;gap: 15px;">
                <mat-card appearance="outlined" class="custom-mat-card">
                    <mat-card-content>
                        <h3>Job Overview</h3>
                        <div>
                            <b>Title:- </b><span>{{jobOverview.title}}</span>
                        </div>
                        <div>
                            <b>Business Type:- </b><span>{{jobOverview.type}}</span>
                        </div>
                        <div>
                            <b>Start Date:- </b><span>{{formatDate(jobOverview.startDate)}}</span>
                        </div>
                        <div>
                            <b>End Date:- </b><span>{{formatDate(jobOverview.endDate)}}</span>
                        </div>
                        <div>
                            <b>Daily Hours:- </b><span>{{jobOverview.dailyHours}}</span>
                        </div>

                        <div>
                            <b>Days per Week:- </b><span>{{jobOverview.daysPerWeek}}</span>
                        </div>

                        <div>
                            <b>Pay Rate:- </b><span>{{formatCurr(jobOverview.payRate)}}</span>
                        </div>
                        <div>
                            <b>Frequency:- </b><span>{{jobOverview.frequency}}</span>
                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-card appearance="outlined" class="custom-mat-card">
                    <mat-card-content> 
                        <h3>Day-to-Day Job Tasks</h3>
                        <div>
                            <b>Day to Day Job Description:- </b><span>{{detailsEntity.description}}</span>
                        </div>
                        <div>
                            <b>In this job did you use: Machine, tools, and equipment usage:- </b><span>{{detailsEntity.equipmentUsage?'Yes':'No'}}</span>
                        </div>
                        <div *ngIf="detailsEntity.equipmentUsage">
                            <b>List Machines Tools Equip:- </b><span>{{detailsEntity.equipmentUsageDesc}}</span>
                        </div>
                        <div>
                            <b>Technical knowledge and skill usage:- </b><span>{{detailsEntity.technicalSkills ? 'Yes': 'No'}}</span>
                        </div>

                        <div *ngIf="detailsEntity.technicalSkills">
                            <b>Please Explain:- </b><span>{{detailsEntity.technicalSkillsDesc}}</span>
                        </div>
                        <div>
                            <b>In this Job, did you: Draft or prepare reports:- </b><span>{{detailsEntity.dexterity?'Yes':'No'}}</span>
                        </div>
                        <div *ngIf="detailsEntity.dexterity">
                            <b>Please Explain:- </b><span>{{detailsEntity.dexterityDesc}}</span>
                        </div>
                        <div>
                            <b>Perform technical writing or memos for leadership:- </b><span>{{detailsEntity.duties?'Yes':'No'}}</span>
                        </div>
                        <div *ngIf="detailsEntity.duties">
                            <b>Please Explain:- </b><span>{{detailsEntity.dutiesDesc}}</span>
                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-card appearance="outlined" class="custom-mat-card">
                    <mat-card-content> 
                        <h3>Exertional Demands of the Job</h3>
                        <div>
                            <b>Walking:- </b><span>{{formatExertional(detailsEntity.walking)}}</span>
                        </div>
                        <div>
                            <b>Standing?:- </b><span>{{formatExertional(detailsEntity.standing)}}</span>
                        </div>
                        <div>
                            <b>Sitting:- </b><span>{{formatExertional(detailsEntity.sitting)}}</span>
                        </div>
                        <div>
                            <b>Climbing:- </b><span>{{formatExertional(detailsEntity.climbing)}}</span>
                        </div>

                        <div>
                            <b>Stooping:- </b><span>{{formatExertional(detailsEntity.stooping)}}</span>
                        </div>
                        <div>
                            <b>Kneeling:- </b><span>{{formatExertional(detailsEntity.kneeling)}}</span>
                        </div>
                        <div>
                            <b>Crouching:- </b><span>{{formatExertional(detailsEntity.crouching)}}</span>
                        </div>
                        <div>
                            <b>Crawling:- </b><span>{{formatExertional(detailsEntity.crawling)}}</span>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card appearance="outlined" class="custom-mat-card">
                    <mat-card-content> 
                        <h3>Lifting Requirements</h3>
                        <div>
                            <b>Heaviest Weight Lifted:- </b><span>{{additionalInfoEntity.liftedWeight}}</span>
                        </div>
                        <div>
                            <b>What did you lift?:- </b><span>{{additionalInfoEntity.whatLiftedWeight}}</span>
                        </div>
                        <div>
                            <b>Weight Lifted:- </b><span>{{additionalInfoEntity.weightFrequency}}</span>
                        </div>
                        <div>
                            <b>What did you lift?:- </b><span>{{additionalInfoEntity.whatWeightFrequency}}</span>
                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-card appearance="outlined" class="custom-mat-card">
                    <mat-card-content> 
                        <h3>Supervisor Duties</h3>
                        <div>
                            <b>Were you a Lead Worker?:- </b><span>{{additionalInfoEntity.leadWorker? 'Yes': 'No'}}</span>
                        </div>
                        <div>
                            <b>Were you a Supervisor?:- </b><span>{{additionalInfoEntity.supervisionDuties? 'Yes': 'No'}}</span>
                        </div>
                        <div *ngIf="additionalInfoEntity.supervisionDuties">
                            <b>How many people did you supervise?:- </b><span>{{additionalInfoEntity.howMany}}</span>
                        </div>
                        <div>
                            <b>I set the work schedule for the staff:- </b><span>{{additionalInfoEntity.workSchedule? 'Yes': 'No'}}</span>
                        </div>

                        <div>
                            <b>When people called in sick, they called me:- </b><span>{{additionalInfoEntity.callInSick? 'Yes': 'No'}}</span>
                        </div>
                        <div>
                            <b>I was involved in budgeting:- </b><span>{{additionalInfoEntity.budgeting? 'Yes': 'No'}}</span>
                        </div>
                        <div>
                            <b>I was the inventory manager:- </b><span>{{additionalInfoEntity.inventoryManager? 'Yes': 'No'}}</span>
                        </div>

                        <div>
                            <b>I managed inventory and was involved in re-ordering:- </b><span>{{additionalInfoEntity.manageInventory? 'Yes': 'No'}}</span>
                        </div>

                        <div>
                            <b>I delegated work responsibilities for the staff/co-workers:- </b><span>{{additionalInfoEntity.staffCoWorkerWork? 'Yes': 'No'}}</span>
                        </div>

                        <div>
                            <b>I assembled and completed reports for the Leadership teams.:- </b><span>{{additionalInfoEntity.leadershipTeamReport? 'Yes': 'No'}}</span>
                        </div>

                        <div>
                            <b>I prepared payroll and/or documented the work hours of the staff:- </b><span>{{additionalInfoEntity.payrollDoc? 'Yes': 'No'}}</span>
                        </div>

                        <div>
                            <b>I called in Payroll:- </b><span>{{additionalInfoEntity.inPayroll? 'Yes': 'No'}}</span>
                        </div>
                        <div>
                            <b>I read resumes and interviewed employees:- </b><span>{{additionalInfoEntity.interviewEmp? 'Yes': 'No'}}</span>
                        </div>
                        <div>
                            <b>I interviewed potential candidates:- </b><span>{{additionalInfoEntity.interviewPotCandi? 'Yes': 'No'}}</span>
                        </div>
                        <div>
                            <b>I hired and fired employees:- </b><span>{{additionalInfoEntity.hireFire? 'Yes': 'No'}}</span>
                        </div>
                        <div>
                            <b>I gave input on performance reviewes:- </b><span>{{additionalInfoEntity.performanceReview? 'Yes': 'No'}}</span>
                        </div>
                        <div>
                            <b>I trained other employees:- </b><span>{{additionalInfoEntity.trainEmp? 'Yes': 'No'}}</span>
                        </div>
                        <div>
                            <b>I coordinated the training program for my Dept:- </b><span>{{additionalInfoEntity.trainingPrograms? 'Yes': 'No'}}</span>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
		</div>
	</div>
</div>
